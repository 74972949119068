import { format } from 'date-fns';
import { LogItem, Player } from '@/interfaces/businessgame';

export const calculateUpdatedLog = ({
  log,
  name,
  player
}: {
  log: LogItem[];
  name: string;
  player: Player;
}) => {
  return [
    ...log,
    {
      date: format(new Date(), 'HH:mm'),
      name,
      player: {
        id: player.id,
        name: player.name,
        avatar: player.avatar
      }
    }
  ];
};
