import { BUYABLE_CARDS, CARD_TYPES, POSSIBLE_ACTIONS_ON_CARDS } from '@/constants';
import { addToTeam, askToBuy, buy, promoteInTeam, removeFromTeam, sell } from '@/helpers/index';
import { addSeconds } from 'date-fns';
import { Game, Card } from '@/interfaces/businessgame';

export const getPossibleActions = (game: Game, previewedCard: Card, user: any) => {
  if (!game || !game.players || previewedCard.type === CARD_TYPES.CORNER) {
    return {};
  }

  const previewedCardHasId = previewedCard && previewedCard.owner;
  const playerId = game.players[game.activePlayerIndex].id;
  const playerMoney = game.players[game.activePlayerIndex].money;

  const isCurrentPlayer = playerId === user.uid;

  if (!isCurrentPlayer) {
    return {};
  }

  return {
    cardPossibleActions: {
      [POSSIBLE_ACTIONS_ON_CARDS.GET_CATEGORY_ONE]:
        isCurrentPlayer &&
        previewedCardHasId &&
        previewedCard.prices &&
        previewedCard.prices.categoryOne.buy <= playerMoney &&
        previewedCard.owner === playerId,
      [POSSIBLE_ACTIONS_ON_CARDS.PROMOTE_CATEGORY_ONE]:
        isCurrentPlayer &&
        previewedCardHasId &&
        previewedCard.team &&
        previewedCard.team.categoryOne &&
        previewedCard.owner === playerId,
      [POSSIBLE_ACTIONS_ON_CARDS.LET_GO_CATEGORY_ONE]:
        isCurrentPlayer &&
        previewedCardHasId &&
        previewedCard.team &&
        previewedCard.team.categoryOne > 0 &&
        previewedCard.owner === playerId,
      [POSSIBLE_ACTIONS_ON_CARDS.GET_CATEGORY_TWO]:
        isCurrentPlayer &&
        previewedCardHasId &&
        previewedCard.prices &&
        previewedCard.prices.categoryTwo.buy <= playerMoney &&
        previewedCard.owner === playerId,
      [POSSIBLE_ACTIONS_ON_CARDS.PROMOTE_CATEGORY_TWO]:
        isCurrentPlayer &&
        previewedCardHasId &&
        previewedCard.team &&
        previewedCard.team.categoryTwo &&
        previewedCard.owner === playerId,
      [POSSIBLE_ACTIONS_ON_CARDS.LET_GO_CATEGORY_TWO]:
        isCurrentPlayer &&
        previewedCardHasId &&
        previewedCard.team &&
        previewedCard.team.categoryTwo > 0 &&
        previewedCard.owner === playerId,
      [POSSIBLE_ACTIONS_ON_CARDS.GET_CATEGORY_THREE]:
        isCurrentPlayer &&
        previewedCardHasId &&
        previewedCard.prices &&
        previewedCard.prices.categoryThree.buy <= playerMoney &&
        previewedCard.owner === playerId,
      [POSSIBLE_ACTIONS_ON_CARDS.PROMOTE_CATEGORY_THREE]:
        isCurrentPlayer &&
        previewedCardHasId &&
        previewedCard.team &&
        previewedCard.team.categoryThree &&
        previewedCard.owner === playerId,
      [POSSIBLE_ACTIONS_ON_CARDS.LET_GO_CATEGORY_THREE]:
        isCurrentPlayer &&
        previewedCardHasId &&
        previewedCard.team &&
        previewedCard.team.categoryThree > 0 &&
        previewedCard.owner === playerId,
      [POSSIBLE_ACTIONS_ON_CARDS.GET_CATEGORY_FOUR]:
        isCurrentPlayer &&
        previewedCardHasId &&
        previewedCard.prices &&
        previewedCard.prices.categoryFour.buy <= playerMoney &&
        previewedCard.owner === playerId,
      [POSSIBLE_ACTIONS_ON_CARDS.LET_GO_CATEGORY_FOUR]:
        isCurrentPlayer &&
        previewedCardHasId &&
        previewedCard.team &&
        previewedCard.team.categoryFour > 0 &&
        previewedCard.owner === playerId
    },
    insideBoardActions: {
      [POSSIBLE_ACTIONS_ON_CARDS.BUY]:
        isCurrentPlayer &&
        !game.activeCard.owner &&
        game.activeCard.price <= playerMoney &&
        BUYABLE_CARDS.includes(game.activeCard.type),
      [POSSIBLE_ACTIONS_ON_CARDS.PASS]:
        isCurrentPlayer &&
        !game.activeCard.owner &&
        game.activeCard.price <= playerMoney &&
        BUYABLE_CARDS.includes(game.activeCard.type)
      // [POSSIBLE_ACTIONS_ON_CARDS.AUCTION]:
      //   isCurrentPlayer && !game.activeCard.owner && BUYABLE_CARDS.includes(game.activeCard.type)
    },
    underPreviewActionsConfig: {
      [POSSIBLE_ACTIONS_ON_CARDS.SELL]:
        isCurrentPlayer &&
        previewedCard.owner &&
        // @ts-ignore
        previewedCard.owner === game.players[game.activePlayerIndex].id,
      [POSSIBLE_ACTIONS_ON_CARDS.ASK_TO_BUY]: false
      // isCurrentPlayer &&
      // previewedCard.owner &&
      // // @ts-ignore
      // previewedCard.owner !== game.players[game.activePlayerIndex].id
    }
  };
};

const startAuction = (game: Game) => {
  const card = { ...game.activeCard };

  delete card.prices;
  delete card.team;

  return {
    timeEnds: addSeconds(Date.now(), 20),
    card: card,
    bidding: {
      value: 0,
      player: null
    }
  };
};

export const performManualAction = (
  actionType: string,
  game: Game,
  previewedCard: Card,
  translation: any,
  locale: string
) => {
  let result = null;

  if (actionType === POSSIBLE_ACTIONS_ON_CARDS.BUY) {
    return {
      ...buy(game, game.activeCard, null, null, translation),
      actionActive: false
    };
  }

  if (actionType === POSSIBLE_ACTIONS_ON_CARDS.PASS) {
    return {
      actionActive: false
    };
  }

  // if (actionType === POSSIBLE_ACTIONS_ON_CARDS.AUCTION) {
  //   return {
  //     auction: startAuction(game),
  //     actionActive: false
  //   };
  // }

  if (!previewedCard || !previewedCard.title) {
    return false;
  }

  switch (actionType) {
    // case POSSIBLE_ACTIONS_ON_CARDS.ASK_TO_BUY:
    //   result = askToBuy(game, previewedCard, translation);
    //   break;
    case POSSIBLE_ACTIONS_ON_CARDS.SELL:
      result = sell(game, previewedCard, translation);
      break;
    case POSSIBLE_ACTIONS_ON_CARDS.GET_CATEGORY_ONE:
      result = addToTeam(game, 'categoryOne', previewedCard, translation, locale);
      break;
    case POSSIBLE_ACTIONS_ON_CARDS.PROMOTE_CATEGORY_ONE:
      result = promoteInTeam(
        game,
        'categoryOne',
        'categoryTwo',
        previewedCard,
        translation,
        locale
      );
      break;
    case POSSIBLE_ACTIONS_ON_CARDS.LET_GO_CATEGORY_ONE:
      result = removeFromTeam(game, 'categoryOne', previewedCard, translation, locale);
      break;
    case POSSIBLE_ACTIONS_ON_CARDS.GET_CATEGORY_TWO:
      result = addToTeam(game, 'categoryTwo', previewedCard, translation, locale);
      break;
    case POSSIBLE_ACTIONS_ON_CARDS.PROMOTE_CATEGORY_TWO:
      result = promoteInTeam(
        game,
        'categoryTwo',
        'categoryThree',
        previewedCard,
        translation,
        locale
      );
      break;
    case POSSIBLE_ACTIONS_ON_CARDS.LET_GO_CATEGORY_TWO:
      result = removeFromTeam(game, 'categoryTwo', previewedCard, translation, locale);
      break;
    case POSSIBLE_ACTIONS_ON_CARDS.GET_CATEGORY_THREE:
      result = addToTeam(game, 'categoryThree', previewedCard, translation, locale);
      break;
    case POSSIBLE_ACTIONS_ON_CARDS.PROMOTE_CATEGORY_THREE:
      result = promoteInTeam(
        game,
        'categoryThree',
        'categoryFour',
        previewedCard,
        translation,
        locale
      );
      break;
    case POSSIBLE_ACTIONS_ON_CARDS.LET_GO_CATEGORY_THREE:
      result = removeFromTeam(game, 'categoryThree', previewedCard, translation, locale);
      break;
    case POSSIBLE_ACTIONS_ON_CARDS.GET_CATEGORY_FOUR:
      result = addToTeam(game, 'categoryFour', previewedCard, translation, locale);
      break;
    case POSSIBLE_ACTIONS_ON_CARDS.LET_GO_CATEGORY_FOUR:
      result = removeFromTeam(game, 'categoryFour', previewedCard, translation, locale);
      break;
  }

  return {
    ...result,
    actionActive: false
  };
};
