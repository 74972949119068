import { computed, ref, onMounted, ComputedRef } from 'vue';
import { isFuture } from 'date-fns';
import { useStore } from 'vuex';
import firebase from 'firebase';
import { useRouter } from 'vue-router';

import useDocument from '@/composables/use-documents';
import useFirebase from '@/composables/use-firebase';
import { emptyPlayer } from '@/constants';
import { calculateCardsValue } from '@/helpers/players-helpers';
import { agreeToSell, GAME_NAMES } from '@/helpers/index';

import { Avatar, Card, Player } from '@/interfaces/businessgame';
import useToast from '@/composables/use-toast';
import { useI18n } from 'vue-i18n';

const db = firebase.firestore();

export default function () {
  const { getCurrentUser, getCurrentGame } = useFirebase();
  const { updateDocument } = useDocument();
  const { t } = useI18n();

  const { push, currentRoute } = useRouter();
  const store = useStore();
  const { errorToast, infoToast } = useToast();

  const user = getCurrentUser();

  const EMPTY_PLAYER = ref(emptyPlayer);
  const currentGame: any = ref(null);

  onMounted(async () => {
    if (!currentRoute.value.params.gameId) {
      errorToast(t('errors.noGameWithLink'));
    } else {
      try {
        // @ts-ignore
        currentGame.value = await getCurrentGame(
          String(currentRoute.value.params.companyId),
          String(currentRoute.value.params.gameId)
        );
      } catch (e) {
        errorToast(e as string);
      }

      if (currentGame.value) {
        checkIfGameIsOutOfTime();
      }

      try {
        db.collection(`companies/${store.state.company.id}/games`)
          // @ts-ignore
          .doc(currentRoute.value.params.gameId)
          .onSnapshot(doc => {
            currentGame.value = doc.data();
            currentGame.value.id = doc.id;
          });
      } catch (e) {
        errorToast(t('errors.somethingWentWrong'));
      }
    }
  });

  const updateGame = async (data: any) => {
    currentGame.value = await updateDocument(data);
  };

  const endGame = async () => {
    const results = [
      ...currentGame.value.players
        .sort((a: Player, b: Player) => {
          return (
            Number(b.money) +
            calculateCardsValue(
              b.owned.map((item: Card) => ({
                ...currentGame.value.boardConfig[item.id - 1]
              }))
            ) -
            (Number(a.money) +
              calculateCardsValue(
                a.owned.map((item: Card) => ({
                  ...currentGame.value.boardConfig[item.id - 1]
                }))
              ))
          );
        })
        .map((player: Player) => ({
          ...player,
          value:
            Number(player.money) +
            calculateCardsValue(
              player.owned.map(item => ({
                ...currentGame.value.boardConfig[item.id - 1]
              }))
            )
        }))
    ];

    updateGame({
      id: currentGame.value.id,
      gameEnded: true,
      endResults: results
    });
  };

  const cancelGame = async () => {
    try {
      await db.doc(`companies/${store.state.company.id}`).update({
        package: {
          ...store.state.company.package,
          gamesLeft: {
            ...store.state.company.package.gamesLeft,
            [GAME_NAMES.FUN_BUSINESS_GAME]:
              store.state.company.package.gamesLeft[GAME_NAMES.FUN_BUSINESS_GAME] + 1
          }
        }
      });

      await db
        .collection(`companies/${store.state.company.id}/games`)
        .doc(currentGame.value.id)
        .update({
          gameCancelled: true
        });
    } catch (e) {
      console.log(e);
    }

    push('/dashboard');
  };

  const checkIfGameShouldEnd = () => {
    if (currentGame.value.players.some((player: Player) => Number(player.money) < 0)) {
      endGame();
      infoToast(t('board.gameEndedDefaulted'));
    }
  };

  const checkIfGameIsOutOfTime = () => {
    const offsetted = currentGame.value.settings.gameFinishTime - store.state.timeOffset;
    if (!isFuture(offsetted) && currentGame.value.gameStarted) {
      endGame();
      infoToast(t('board.gameEndedTimeElapsed'));
    }
  };

  const startGame = async () => {
    updateGame({
      id: currentGame.value.id,
      gameStarted: true,
      activePlayerIndex: Math.floor(Math.random() * currentGame.value.players.length),
      settings: {
        ...currentGame.value.settings,
        gameFinishTime:
          Date.now() +
          // defaults to 30 days max game time
          (currentGame.value.duration ? currentGame.value.duration * 60000 : 60000 * 24 * 30 * 60)
      }
    });
  };

  const joinGame = async ({ name, selectedAvatar }: { name: string; selectedAvatar: Avatar }) => {
    if (!user?.uid) {
      await firebase.auth().signInAnonymously();
    }

    store.commit('toggleSound', true);

    updateGame({
      id: currentGame.value.id,
      players: firebase.firestore.FieldValue.arrayUnion(
        ...[
          {
            ...emptyPlayer,
            money: currentGame.value.playerMoneyAtStart,
            id: user?.uid,
            email: user?.email || null,
            avatar: selectedAvatar,
            name,
            admin: !currentGame.value.players.length
          }
        ]
      )
    });

    if (!currentGame.value.players.length) {
      infoToast(t('newGame.firstPlayerToBeAdmin'), 6000);
      store.commit('toggleSound', true);
    }
  };

  const showJoinGame = computed(() => {
    if (currentGame.value.gameCreator?.id === user?.uid) {
      return false;
    }

    return (
      currentGame.value &&
      currentGame.value.players &&
      !currentGame.value.auction &&
      !currentGame.value.buyOffer &&
      !currentGame.value.actionActive &&
      user &&
      user.uid &&
      !currentGame.value.players.find((player: Player) => player.id === user.uid)
    );
  });

  const handleBid = async (value: number) => {
    updateGame({
      id: currentGame.value.id,
      auction: {
        ...currentGame.value.auction,
        bidding: {
          value,
          player: user?.uid
        }
      }
    });
  };

  const endAuction = async (data: any) => {
    updateGame({
      id: currentGame.value.id,
      ...data,
      auction: null,
      activePlayerIndex:
        currentGame.value.activePlayerIndex === currentGame.value.players.length - 1
          ? 0
          : currentGame.value.activePlayerIndex + 1
    });
  };

  const finaliseBuyOffer = async (consent: boolean) => {
    if (!consent) {
      updateGame({
        id: currentGame.value.id,
        buyOffer: null
      });
      const data = agreeToSell(currentGame.value);
      updateGame({
        id: currentGame.value.id,
        ...data,
        buyOffer: null
      });
    }
  };

  const showPlayerStats = computed(
    () => currentGame.value && currentGame.value.players && currentGame.value.players.length
  );

  const showStartGameButton = computed(() => {
    return (
      currentGame.value &&
      !currentGame.value.gameStarted &&
      !currentGame.value.gameCancelled &&
      currentGame.value.players &&
      currentGame.value.players.length > 1 &&
      user &&
      currentGame.value.players.find((player: Player) => player.admin).id === user.uid
    );
  });

  const currentPlayer: ComputedRef<Player> = computed(() => {
    return currentGame.value.players.find((player: Player) => player.id === store.state.user.id);
  });

  const showDebugThings = computed(() => user && user.email === 'biley94@gmail.com');

  const deletePlayer = async (playerId: string) => {
    await updateGame({
      activePlayerIndex:
        currentGame.value.activePlayerIndex === currentGame.value.players.length - 1
          ? 0
          : currentGame.value.activePlayerIndex,
      id: currentGame.value.id,
      players: currentGame.value.players.filter((p: Player) => p.id !== playerId),
      boardConfig: currentGame.value.boardConfig.map((card: Card) => {
        if (card.owner === playerId) {
          return {
            ...card,
            owner: '',
            ...(card.team && {
              team: {
                categoryOne: 0,
                categoryTwo: 0,
                categoryThree: 0,
                categoryFour: 0
              }
            })
          };
        } else {
          return card;
        }
      })
    });
  };

  const addAdmin = async (id: string) => {
    const game = currentGame.value;
    const playerIndex = game.players.findIndex((p: Player) => p.id === id);

    await updateGame({
      id: game.id,
      players: [
        ...game.players.slice(0, playerIndex),
        {
          ...game.players[playerIndex],
          admin: true
        },
        ...game.players.slice(playerIndex + 1)
      ]
    });
  };

  const forceNextPlayer = async () => {
    const game = currentGame.value;

    await updateGame({
      id: game.id,
      auction: null,
      actionActive: false,
      activePlayerIndex:
        game.activePlayerIndex === game.players.length - 1 ? 0 : game.activePlayerIndex + 1,
      diceConfig: {
        ...game.diceConfig,
        rolled: false
      }
    });
  };

  return {
    activeGame: currentGame,
    EMPTY_PLAYER,
    user,
    showJoinGame,
    showPlayerStats,
    showStartGameButton,
    showDebugThings,
    currentPlayer,
    endGame,
    checkIfGameShouldEnd,
    joinGame,
    startGame,
    handleBid,
    endAuction,
    finaliseBuyOffer,
    updateGame,
    cancelGame,
    deletePlayer,
    addAdmin,
    forceNextPlayer
  };
}
