
  import { defineComponent } from 'vue';
  import Feedback from '@/components/common/Feedback.vue';

  export default defineComponent({
    name: 'EndResults',
    components: {
      Feedback
    },
    props: {
      results: {
        type: Array,
        default: () => []
      }
    }
  });
