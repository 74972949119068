
  import { ref, computed, defineComponent, onMounted, Ref, ComputedRef } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import { useRouter } from 'vue-router';

  import LanguageSelection from '@/components/common/LanguageSelection.vue';
  import IconAttribute from '@/components/common/IconAttribute.vue';

  import { emptyPlayer, emptyGame, BOARD_CONFIGS_TEMPLATES } from '@/constants';
  import useFirebase from '@/composables/use-firebase';
  import useToast from '@/composables/use-toast';
  import { AvatarObject } from '@/constants/avatars';
  import { Avatar } from '@/interfaces/businessgame';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'NewGame',
    components: {
      LanguageSelection,
      IconAttribute
    },
    setup() {
      const { getCurrentUser, addAlertThatNewGameStarted } = useFirebase();
      const user = getCurrentUser();

      const { push } = useRouter();
      const store = useStore();
      const { successToast, warningToast, errorToast } = useToast();
      const { t } = useI18n();

      const gameId: Ref<string> = ref('');
      const newGameLoading: Ref<boolean> = ref(false);
      const name: Ref<string> = ref('');
      const cash: Ref<string> = ref('3000');
      const audio: Ref<boolean> = ref(true);
      const gameSounds: Ref<boolean> = ref(true);
      const gameFinishTime: Ref<number> = ref(0);
      const selectedAvatar: Ref<Avatar | null> = ref(null);
      const company: Ref = ref(store.state.company);
      const boardTemplates: Ref = ref(BOARD_CONFIGS_TEMPLATES);
      const boardTemplate = ref(null);

      const link: ComputedRef<string> = computed(() =>
        gameId.value
          ? `https://${window.location.hostname}/funbusinessgame/${company.value.id}/${gameId.value}`
          : ''
      );

      const avatars: ComputedRef<AvatarObject> = computed(() => company.value.avatars);

      const getNoRegisterCompany = async () => {
        company.value = await db
          .doc(`companies/${process.env.VUE_APP_FREE_COMPANY}`)
          .get()
          .then(doc => ({ id: doc.id, ...doc.data() }));
      };

      onMounted(async () => {
        try {
          await getNoRegisterCompany();
        } catch (e) {
          errorToast(e);
        }
      });

      const getBoardConfig = async () => {
        return await db
          .doc(`boardConfigs/${boardTemplate.value}`)
          .get()
          .then(doc => doc.data())
          .catch(e => {
            throw new Error(e);
          });
      };

      const getChanceCards = async () => {
        return await db
          .doc(`chanceCardsConfigs/${boardTemplate.value}`)
          .get()
          .then(doc => doc.data())
          .catch(e => {
            throw new Error(e);
          });
      };

      const createNewGame = async (game: any) => {
        delete game.id;

        return await db
          .collection(`companies/${company.value.id}/games`)
          .add({
            ...game,
            createdOn: Date.now()
          })
          .then(docRef => {
            return docRef.get();
          })
          .then(doc => {
            return doc.id;
          });
      };

      const addGame = async (): Promise<any> => {
        if (!company.value.id) {
          errorToast(t('common.somethingWrong'));
          return;
        }

        if (name.value.length > 15) {
          warningToast(t('warnings.cantBeLongerThan', { value: 15 }));
          return;
        }

        if (newGameLoading.value) {
          return;
        }

        newGameLoading.value = true;

        if (!user?.uid) {
          await firebase.auth().signInAnonymously();
        }
        const newUser = getCurrentUser();

        const playerData = {
          ...emptyPlayer,
          money: Number(cash.value),
          name: name.value,
          avatar: selectedAvatar.value,
          email: newUser?.email || null,
          id: newUser?.uid,
          admin: true
        };

        const boardConfig = await getBoardConfig();
        const chanceCards = await getChanceCards();

        const newDocument = {
          ...emptyGame,
          chanceCards: chanceCards?.cards,
          initialChanceCards: chanceCards?.cards,
          boardConfig: boardConfig?.config,
          upgradeConfig: boardConfig?.upgradeConfig,
          playerMoneyAtStart: Number(cash.value),
          avatars: avatars.value,
          duration: Number(gameFinishTime.value),
          settings: {
            audioOnNextPlayer: audio.value,
            gameSounds: gameSounds.value,
            // defaults to 30 days max game time
            gameFinishTime: Date.now() + 60000 * 24 * 30 * 60
          },
          players: [playerData],
          gameCreator: playerData
        };

        try {
          gameId.value = await createNewGame(newDocument);
          await addAlertThatNewGameStarted(
            'FunBusinessGame',
            company.value.id,
            company.value.name,
            gameId.value
          );
          successToast(t('success.gameCreated'));
          store.commit('toggleSound', true);
          newGameLoading.value = false;
          goToGame();
        } catch (e) {
          newGameLoading.value = false;
          errorToast(e);
        }
      };

      const copy = (): void => {
        navigator.clipboard.writeText(link.value);
        successToast(t('common.copiedToClipboard'));
      };

      const goToGame = (): void => {
        push({
          name: 'funbusinessgame-game',
          params: { companyId: company.value.id, gameId: gameId.value }
        });
      };

      return {
        name,
        cash,
        link,
        avatars,
        user,
        audio,
        gameSounds,
        newGameLoading,
        selectedAvatar,
        gameFinishTime,
        goToGame,
        copy,
        addGame,
        boardTemplates,
        boardTemplate,
        company
      };
    }
  });
