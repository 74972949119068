
  import { defineComponent, PropType } from 'vue';
  import { LogItem } from '@/interfaces/businessgame';

  export default defineComponent({
    name: 'Log',
    props: {
      log: {
        type: Array as PropType<LogItem[]>,
        default: () => []
      }
    }
  });
