
  import { ref, defineComponent } from 'vue';
  import { POSSIBLE_ACTIONS_ON_CARDS_WITH_DECISION } from '@/constants';

  export default defineComponent({
    name: 'PossibleActions',
    props: {
      activeCard: {
        type: Object,
        default: () => ({})
      },
      actions: {
        type: Object,
        default: () => ({})
      }
    },
    emits: ['selected'],
    setup(props, { emit }) {
      const handleClick = (action: string) => {
        emit('selected', { actionType: action, nextPlayer: true });
      };

      const POSSIBLE_ACTIONS = ref(POSSIBLE_ACTIONS_ON_CARDS_WITH_DECISION);

      return { handleClick, POSSIBLE_ACTIONS };
    }
  });
