
  import { computed, defineComponent } from 'vue';

  import Board from '@/components/Board.vue';
  import LanguageSelection from '@/components/common/LanguageSelection.vue';
  import IconAttribute from '@/components/common/IconAttribute.vue';
  import SoundMuting from '@/components/SoundMuting.vue';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'Game',
    components: {
      LanguageSelection,
      IconAttribute,
      Board,
      SoundMuting
    },
    setup() {
      const { t } = useI18n();

      const instructions = computed(() => [
        t('funBusinessGameShortInstructions.0'),
        t('funBusinessGameShortInstructions.1'),
        t('funBusinessGameShortInstructions.2'),
        t('funBusinessGameShortInstructions.3'),
        t('funBusinessGameShortInstructions.4'),
        t('funBusinessGameShortInstructions.5')
      ]);

      return { instructions };
    }
  });
