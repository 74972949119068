
  import { defineComponent, PropType } from 'vue';

  import { Player } from '@/interfaces/businessgame';
  import { AvatarObject } from '@/constants/avatars';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';
  import { FunIceBreakerPlayer } from '@/interfaces/funicebreaker';

  export default defineComponent({
    name: 'JoinGame',
    components: { BaseButtonWithSpinner },

    props: {
      players: {
        type: Array as PropType<Player[]>,
        default: () => []
      },
      avatars: {
        type: Object as PropType<AvatarObject>,
        default: () => ({})
      },
      joinLoading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        name: '',
        selectedAvatar: null
      };
    },
    computed: {
      availableAvatars(): string[] {
        const takenAvatars = this.players.map((p: Player) => p.avatar.name);

        return this.avatars.list.filter((avatar: string) => !takenAvatars.includes(avatar));
      },
      nameTaken(): boolean {
        const names = this.players.map((p: Player) => p.name);

        return names.includes(this.name);
      }
    }
  });
