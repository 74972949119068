<template>
  <div>
    <!--    <h2 class="welcome-title mb3">-->
    <!--      {{ $t('gamesCommon.welcome') }}-->
    <!--    </h2>-->
    <div v-if="isCurrentUserAdmin" class="welcome-description my3">
      {{ $t('gamesCommon.inviteOthers') }}
    </div>
    <div class="welcome-description my3">
      {{ isCurrentUserAdmin ? $t('gamesCommon.youWillStart') : $t('gamesCommon.adminWillStart') }}
    </div>
    <div class="flex items-start mt3 flex-wrap justify-center">
      <div class="m1">
        <Button @click="$emit('copy')">
          {{ $t('newGame.copyLink') }}
        </Button>
      </div>
      <a href="https://meet.google.com/new" target="_blank" class="m1">
        <Button>
          {{ $t('newGame.generateGoogleMeet') }}
        </Button>
      </a>
      <div v-if="isCurrentUserAdmin && isUserAnOrganisationUser" class="m1">
        <BaseButtonWithSpinner @click="$emit('cancel')" :loading="cancelLoading">
          {{ $t('common.cancelGame') }}
        </BaseButtonWithSpinner>
      </div>
    </div>
    <div class="mt3">
      <Button v-if="showStart" @click="$emit('start')">
        <b>{{ $t('common.startGame') }}</b>
      </Button>
    </div>
  </div>
</template>

<script>
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner';

  export default {
    name: 'BaseWaitingForOtherPlayers',
    components: { BaseButtonWithSpinner },
    props: {
      showStart: {
        type: Boolean,
        default: false
      },
      cancelLoading: {
        type: Boolean,
        default: false
      },
      isCurrentUserAdmin: {
        type: Boolean,
        default: false
      },
      isUserAnOrganisationUser: {
        type: Boolean,
        default: false
      }
    },
    emits: ['cancel', 'start', 'copy']
  };
</script>
