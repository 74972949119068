
  import { watch, computed, shallowReactive, defineComponent, PropType, ComputedRef } from 'vue';

  import BoardCard from '@/components/BoardCard.vue';
  import BaseTooltipWrapper from '@/components/common/BaseTooltipWrapper.vue';

  import { getMoneySound, nextPlayerSound, paySound } from '@/composables/use-audio';

  import { calculateCardCharge, calculateCardsValue } from '@/helpers/players-helpers';
  import { Card, CardWithPrice, Player } from '@/interfaces/businessgame';
  import { useStore } from 'vuex';
  import useBoard from '@/composables/use-board';
  import { useConfirm } from 'primevue/useconfirm';
  import { useI18n } from 'vue-i18n';
  import useToast from '@/composables/use-toast';

  interface State {
    statsOpen: boolean;
    activatedStats: any;
    activatedPlayerIndex: number;
    moneyAdded: boolean;
    moneyRemoved: boolean;
  }

  export default defineComponent({
    name: 'PlayerStats',
    components: {
      BoardCard,
      BaseTooltipWrapper
    },
    props: {
      activePlayerIndex: {
        type: Number,
        default: 0
      },
      playNextPlayerSound: {
        type: Boolean,
        default: false
      },
      players: {
        type: Array as PropType<Player[]>,
        default: () => []
      },
      cardActions: {
        type: Object,
        default: () => ({})
      },
      boardConfig: {
        type: Array as PropType<Card[]>,
        default: () => []
      },
      user: {
        type: Object,
        default: () => ({})
      }
    },
    setup(props) {
      const store = useStore();
      const { deletePlayer } = useBoard();
      const confirm = useConfirm();
      const { t } = useI18n();
      const { infoToast } = useToast();

      const state: State = shallowReactive({
        statsOpen: false,
        activatedStats: null,
        activatedPlayerIndex: 0,
        moneyAdded: false,
        moneyRemoved: false
      });

      const getPlayerValue = (index: number) => {
        return calculateCardsValue(mapCardsToGetPrices(index)) + Number(props.players[index].money);
      };

      const getCardCharge = (card: Card) => calculateCardCharge(props.boardConfig, card);

      // @ts-ignore
      const yourPlayer: ComputedRef<Player> = computed(() => {
        // @ts-ignore
        return props.players.find((p: Player) => p.id === props.user.uid);
      });

      const checkIfPlayerCanBeDeleted = (id: string) => {
        return yourPlayer.value?.admin && yourPlayer.value?.id !== id;
      };

      watch(
        () => props.activePlayerIndex,
        newVal => {
          const yourPlayerIndex = props.players.findIndex(
            // @ts-ignore
            (p: Player) => p.id === props.user.uid
          );

          if (props.playNextPlayerSound && newVal === yourPlayerIndex && store.state.soundOn) {
            nextPlayerSound.play();
          }
        }
      );

      watch(
        () => yourPlayer.value?.money,
        (newVal: number, oldVal: number) => {
          if (newVal > oldVal) {
            if (store.state.soundOn) {
              getMoneySound.play();
            }
            state.moneyAdded = true;

            setTimeout(() => {
              state.moneyAdded = false;
            }, 1000);
          } else if (newVal < oldVal) {
            if (store.state.soundOn) {
              paySound.play();
            }
            state.moneyRemoved = true;

            setTimeout(() => {
              state.moneyRemoved = false;
            }, 1000);
          }
        }
      );

      const mapCardsToGetPrices = (index: number) => {
        return [
          // @ts-ignore
          ...props.players[index].owned
            .map((card: any) => ({
              ...card,
              ...props.boardConfig.find(boardCard => boardCard.id === card.id)
            }))
            .sort((a: CardWithPrice, b: CardWithPrice) => a.price - b.price)
        ];
      };

      const activatePlayerStats = (index: number) => {
        state.activatedStats = {
          // @ts-ignore
          ...props.players[index],
          owned: mapCardsToGetPrices(index)
        };
        state.statsOpen = true;
        state.activatedPlayerIndex = index;
      };

      const clearStats = () => {
        state.statsOpen = false;
        state.activatedStats = null;
      };

      const handleDeletePlayer = (playerId: string) => {
        confirm.require({
          header: t('common.areYouSure'),
          message: '',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            deletePlayer(playerId);
            infoToast(t('common.playerRemoved'));
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        });
      };

      return {
        state,
        yourPlayer,
        activatePlayerStats,
        clearStats,
        calculateCardsValue,
        getPlayerValue,
        mapCardsToGetPrices,
        getCardCharge,
        checkIfPlayerCanBeDeleted,
        handleDeletePlayer
      };
    }
  });
