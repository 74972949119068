
  import { ref, onMounted, defineComponent } from 'vue';
  import { formatDistanceToNowStrict, formatDistanceToNow, isFuture } from 'date-fns';
  import useBoard from '@/composables/use-board';
  import { useStore } from 'vuex';
  import { enGB, pl } from 'date-fns/locale';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'GameFinishTime',
    props: {
      finishTime: {
        type: Number,
        default: 0
      }
    },
    setup(props) {
      const timeLeft = ref('');
      let interval = 0;
      let intervalTime = 0;
      const { locale } = useI18n();
      const { endGame } = useBoard();
      const store = useStore();

      onMounted(() => {
        interval = setInterval(() => handleCheckTime(), 60000);
        intervalTime = 60000;

        handleCheckTime();
      });

      const handleCheckTime = () => {
        if (!props.finishTime) {
          timeLeft.value = '';
          return;
        }

        const offsetted: number = props.finishTime - store.state.timeOffset;

        if (!isFuture(offsetted)) {
          endGame();
          clearInterval(interval);
          return;
        }

        timeLeft.value = formatDistanceToNowStrict(offsetted, {
          locale: locale.value === 'pl' ? pl : enGB
        });

        if (
          (formatDistanceToNow(offsetted) === '1 minute' ||
            formatDistanceToNow(offsetted) === 'less than a minute') &&
          intervalTime !== 1000
        ) {
          interval = setInterval(() => handleCheckTime(), 1000);
          intervalTime = 1000;
        }
      };

      return {
        timeLeft
      };
    }
  });
