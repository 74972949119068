import { POSSIBLE_ACTIONS_ON_CARDS } from '@/constants';

import { format } from 'date-fns';
import { goToBenchSound } from '@/composables/use-audio';
import { TakeChancePayload, Player, ChanceCard, Card } from '@/interfaces/businessgame';
import { messages } from '@/locales';
import { v4 as uuidv4 } from 'uuid';

export const takeChance = ({
  initialChanceCards,
  chanceCards,
  player,
  players,
  log,
  activePlayerIndex,
  bonusMoney,
  boardConfig,
  translation
}: TakeChancePayload) => {
  const randomCardIndex = Math.floor(Math.random() * chanceCards.length);

  const updatedInsideCard = {
    ...chanceCards[randomCardIndex],
    player: player.name
  };

  let updatedChanceCards = chanceCards.filter((card, index) => index !== randomCardIndex);

  if (!updatedChanceCards.length) {
    // reset cards
    updatedChanceCards = initialChanceCards;
  }

  let chanceResult = null;

  if (updatedInsideCard.method) {
    // @ts-ignore
    chanceResult = methodsMap[updatedInsideCard.method]({
      player,
      activeInsideCard: updatedInsideCard,
      players,
      activePlayerIndex,
      bonusMoney,
      boardConfig,
      log: [
        ...log,
        {
          name: translation('log.tookChanceCard'),
          date: format(new Date(), 'HH:mm'),
          player: {
            id: player.id,
            name: player.name,
            avatar: player.avatar
          }
        }
      ]
    });
  }

  return {
    ...chanceResult,
    updatedInsideCard,
    updatedChanceCards
  };
};

export const payZUS = ({
  player,
  translation,
  bonusMoney
}: {
  player: Player;
  translation: any;
  bonusMoney: number;
}) => {
  return {
    updatedInsideCard: {
      id: uuidv4(),
      descriptionEn: messages.en.log.payTaxAlert,
      descriptionPl: messages.pl.log.payTaxAlert,
      type: 'tax',
      player: player.name
    },
    log: [
      {
        name: translation('log.payTaxDescription', {
          value: Math.round(player.payroll * 0.5)
        }),
        date: format(new Date(), 'HH:mm'),
        player: {
          id: player.id,
          name: player.name,
          avatar: player.avatar
        }
      }
    ],
    updatedPlayer: {
      ...player,
      money: Number(player.money) - Math.round(player.payroll * 0.5)
    },
    bonusMoney: bonusMoney + Math.round(player.payroll * 0.5)
  };
};

export const goToBench = ({
  player,
  translation,
  bonusMoney
}: {
  player: Player;
  translation: any;
  bonusMoney: number;
}) => {
  goToBenchSound.play();

  return {
    updatedInsideCard: {
      id: uuidv4(),
      descriptionEn: messages.en.log.benchAlert,
      descriptionPl: messages.pl.log.benchAlert,
      type: 'penalty',
      player: player.name
    },
    log: [
      {
        name: translation('log.benchDescription'),
        date: format(new Date(), 'HH:mm'),
        player: {
          id: player.id,
          name: player.name,
          avatar: player.avatar
        }
      }
    ],
    updatedPlayer: {
      ...player,
      money: Number(player.money) - Math.round(player.payroll * 0.25),
      roundsLeft: 1,
      waitRounds: player.waitRounds + 1,
      boardField: 10
    },
    bonusMoney: bonusMoney + Math.round(player.payroll * 0.25)
  };
};
export const takeBonusMoney = ({
  player,
  bonusMoney,
  translation
}: {
  player: Player;
  bonusMoney: number;
  translation: any;
}) => {
  if (bonusMoney === 0) {
    return {};
  }

  return {
    updatedInsideCard: {
      id: uuidv4(),
      descriptionEn: `${messages.en.log.bonusAlert} - ${bonusMoney}`,
      descriptionPl: `${messages.pl.log.bonusAlert} - ${bonusMoney}`,
      type: 'bonus',
      player: player.name
    },
    log: [
      {
        name: translation('log.bonusDescription', { value: bonusMoney }),
        date: format(new Date(), 'HH:mm'),
        player: {
          id: player.id,
          name: player.name,
          avatar: player.avatar
        }
      }
    ],
    updatedPlayer: {
      ...player,
      money: Number(player.money) + bonusMoney
    },
    bonusMoney: 0
  };
};

export const addRoundToCurrentPlayer = ({
  activeInsideCard,
  player
}: {
  activeInsideCard: ChanceCard;
  player: Player;
}) => {
  return {
    updatedPlayer: {
      ...player,
      roundsLeft: player.roundsLeft + Number(activeInsideCard.payload)
    }
  };
};

export const addMoneyToCurrentPlayer = ({
  activeInsideCard,
  player
}: {
  activeInsideCard: ChanceCard;
  player: Player;
}) => {
  return {
    updatedPlayer: {
      ...player,
      money: Number(player.money) + Number(activeInsideCard.payload)
    }
  };
};

export const removeMoneyFromCurrentPlayer = ({
  activeInsideCard,
  player,
  bonusMoney
}: {
  activeInsideCard: ChanceCard;
  player: Player;
  bonusMoney: number;
}) => {
  return {
    updatedPlayer: {
      ...player,
      money: Number(player.money) - Number(activeInsideCard.payload)
    },
    bonusMoney: bonusMoney + Number(activeInsideCard.payload)
  };
};

export const addToPayroll = ({
  activeInsideCard,
  player
}: {
  activeInsideCard: ChanceCard;
  player: Player;
}) => {
  return {
    updatedPlayer: {
      ...player,
      payroll: player.payroll + Number(activeInsideCard.payload)
    }
  };
};

export const removeFromPayroll = ({
  activeInsideCard,
  player
}: {
  activeInsideCard: ChanceCard;
  player: Player;
}) => {
  return {
    updatedPlayer: {
      ...player,
      payroll: player.payroll - Number(activeInsideCard.payload)
    }
  };
};
export const removeMoneyFromCurrentPlayerIfConditions = ({
  activeInsideCard,
  player,
  bonusMoney
}: {
  activeInsideCard: ChanceCard;
  player: Player;
  bonusMoney: number;
}) => {
  const { money }: any = activeInsideCard.payload;
  const updatedPlayer = { ...player };

  return {
    updatedPlayer,
    bonusMoney: bonusMoney + (money || 0)
  };
};

export const addMoneyToPayrollIfConditions = ({ player }: { player: Player }) => {
  const updatedPlayer = { ...player };

  return {
    updatedPlayer
  };
};

export const addMoneyToCurrentPlayerIfConditions = ({
  activeInsideCard,
  player
}: {
  activeInsideCard: ChanceCard;
  player: Player;
}) => {
  const { value, money, type }: any = activeInsideCard.payload;
  const updatedPlayer = { ...player };

  if (type === 'cardsMax' && player.owned.length <= value) {
    updatedPlayer.money += money;
  }

  return {
    updatedPlayer
  };
};

export const changeTeamMembers = ({
  activeInsideCard,
  player,
  boardConfig
}: {
  activeInsideCard: ChanceCard;
  player: Player;
  boardConfig: Card[];
}) => {
  const { from, to, max }: any = activeInsideCard.payload;
  const updatedBoardConfig = [...boardConfig];

  player.owned.forEach(ownedCard => {
    const card = updatedBoardConfig[ownedCard.id - 1];

    if (!card.team) {
      return;
    }

    // @ts-ignore
    if (card.team && card.team[from] <= 0) {
      return;
    }

    if (to) {
      // @ts-ignore
      card.team[to] = max
        ? // @ts-ignore
          card.team[to] + max
        : // @ts-ignore
          card.team[to] + card.team[from];
    }

    if (from) {
      // @ts-ignore
      const result = max ? card.team[from] - max : 0;
      // @ts-ignore
      card.team[from] = result < 0 ? 0 : result;
    }
  });

  return {
    boardConfig: updatedBoardConfig,
    log: []
  };
};

export const methodsMap = {
  addRoundToCurrentPlayer: addRoundToCurrentPlayer,
  addMoneyToCurrentPlayer: addMoneyToCurrentPlayer,
  removeMoneyFromCurrentPlayer: removeMoneyFromCurrentPlayer,
  addToPayroll: addToPayroll,
  removeFromPayroll: removeFromPayroll,
  removeMoneyFromCurrentPlayerIfConditions: removeMoneyFromCurrentPlayerIfConditions,
  addMoneyToPayrollIfConditions: addMoneyToPayrollIfConditions,
  addMoneyToCurrentPlayerIfConditions: addMoneyToCurrentPlayerIfConditions,
  changeTeamMembers: changeTeamMembers
};

export const ACTION_FUNCTIONS = {
  [POSSIBLE_ACTIONS_ON_CARDS.PAY_ZUS]: payZUS,
  [POSSIBLE_ACTIONS_ON_CARDS.TAKE_CHANCE]: takeChance,
  [POSSIBLE_ACTIONS_ON_CARDS.GO_TO_PRISON]: goToBench,
  [POSSIBLE_ACTIONS_ON_CARDS.TAKE_BONUS_MONEY]: takeBonusMoney
};
