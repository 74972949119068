
  import { ref, computed, defineComponent, onMounted, Ref, ComputedRef } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import { useRouter } from 'vue-router';

  import LanguageSelection from '@/components/common/LanguageSelection.vue';
  import IconAttribute from '@/components/common/IconAttribute.vue';

  import { emptyPlayer, emptyGame } from '@/constants';
  import useFirebase from '@/composables/use-firebase';
  import useToast from '@/composables/use-toast';
  import { AvatarObject } from '@/constants/avatars';
  import { Avatar } from '@/interfaces/businessgame';
  import { GAME_NAMES, GAME_NAMES_HUMANIZED } from '@/helpers';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'NewGame',
    components: {
      LanguageSelection,
      IconAttribute
    },
    setup() {
      const { getCurrentUser, removeOneGameFromPackage, addAlertThatNewGameStarted } =
        useFirebase();
      const user = getCurrentUser();

      const { push } = useRouter();
      const store = useStore();
      const { successToast, warningToast, errorToast } = useToast();
      const { t } = useI18n();

      const gameId: Ref<string> = ref('');
      const newGameLoading: Ref<boolean> = ref(false);
      const name: Ref<string> = ref('');
      const cash: Ref<string> = ref('3000');
      const audio: Ref<boolean> = ref(true);
      const gameSounds: Ref<boolean> = ref(true);
      const creatorShouldPlayGame: Ref<boolean> = ref(true);
      const gameFinishTime: Ref<number> = ref(0);
      const selectedAvatar: Ref<Avatar | null> = ref(null);

      const link: ComputedRef<string> = computed(() =>
        gameId.value
          ? `https://${window.location.hostname}/funbusinessgame/${company.value.id}/${gameId.value}`
          : ''
      );

      const company: ComputedRef = computed(() => {
        return store.state.company;
      });

      const userHasPermissionsToCreateGames: ComputedRef = computed(() => {
        return store.state.user?.roles?.gameCreator;
      });

      const avatars: ComputedRef<AvatarObject> = computed(() => store.state.company.avatars);

      const buttonDisabled: ComputedRef = computed(() => {
        if (creatorShouldPlayGame.value) {
          return (
            !(name.value && cash.value && selectedAvatar.value) ||
            !userHasPermissionsToCreateGames.value ||
            name.value.length > 15
          );
        } else {
          return !cash.value || !userHasPermissionsToCreateGames.value;
        }
      });

      onMounted(async () => {
        try {
          await getCurrentCompany();
          await getCurrentUserRoles();

          if (
            !store.state.company ||
            !store.state.company.package ||
            !store.state.company.package.gamesLeft[GAME_NAMES.FUN_BUSINESS_GAME] ||
            store.state.company.package.gamesLeft[GAME_NAMES.FUN_BUSINESS_GAME] === 0
          ) {
            errorToast(
              t('errors.noPackageOrGames', {
                name: GAME_NAMES_HUMANIZED.FUN_BUSINESS_GAME
              })
            );
            return;
            // logic to help user buy new ones
          }
        } catch (e) {
          errorToast(e);
        }
      });

      const getCurrentCompany = async () => {
        const company = await db
          .collection('companies')
          .where('userEmails', 'array-contains', user?.email)
          .get()
          .then((querySnapshot: any) => {
            const resultArray: any = [];

            querySnapshot.forEach((doc: any) => {
              resultArray.push({ id: doc.id, ...doc.data() });
            });

            return resultArray[0];
          });

        store.commit('setCompany', company);
      };

      const getCurrentUserRoles = async () => {
        const response = await db
          .doc(`companies/${store.state.company.id}/users/${user?.email}`)
          .get()
          .then(doc => doc.data());

        if (!response?.roles?.gameCreator) {
          warningToast(t('warnings.noPermissionsToCreateGames'));
        }
        store.commit('setCurrentUserRoles', response?.roles);
      };

      const createNewGame = async (game: any) => {
        delete game.id;

        return await db
          .collection(`companies/${store.state.company.id}/games`)
          .add({
            ...game,
            createdOn: Date.now()
          })
          .then(docRef => {
            return docRef.get();
          })
          .then(doc => {
            return doc.id;
          });
      };

      const addGame = async (): Promise<any> => {
        if (name.value.length > 15) {
          warningToast(t('warnings.cantBeLongerThan', { value: 15 }));
          return;
        }

        if (newGameLoading.value) {
          return;
        }

        newGameLoading.value = true;

        // so that we have newest data
        await getCurrentCompany();

        if (
          !store.state.company ||
          !store.state.company.package ||
          !store.state.company.package.gamesLeft[GAME_NAMES.FUN_BUSINESS_GAME] ||
          store.state.company.package.gamesLeft[GAME_NAMES.FUN_BUSINESS_GAME] === 0
        ) {
          errorToast(
            t('errors.noPackageOrGames', {
              name: GAME_NAMES_HUMANIZED.FUN_BUSINESS_GAME
            })
          );
          newGameLoading.value = false;
          return;
          // logic to help user buy new ones
        }

        const playerData = {
          ...emptyPlayer,
          money: Number(cash.value),
          name: name.value,
          avatar: selectedAvatar.value,
          email: user?.email || null,
          id: user?.uid,
          admin: true
        };

        const newDocument = {
          ...emptyGame,
          chanceCards: store.state.company.chanceCards,
          initialChanceCards: store.state.company.chanceCards,
          boardConfig: store.state.company.boardConfig,
          upgradeConfig: store.state.company.upgradeConfig,
          playerMoneyAtStart: Number(cash.value),
          avatars: avatars.value,
          duration: Number(gameFinishTime.value),
          settings: {
            audioOnNextPlayer: audio.value,
            gameSounds: gameSounds.value,
            // defaults to 30 days max game time
            gameFinishTime: Date.now() + 60000 * 24 * 30 * 60
          },
          players: creatorShouldPlayGame.value ? [playerData] : [],
          gameCreator: playerData
        };

        try {
          gameId.value = await createNewGame(newDocument);
          await removeOneGameFromPackage();
          await addAlertThatNewGameStarted(
            'FunBusinessGame',
            store.state.company.id,
            store.state.company.name,
            gameId.value
          );
          successToast(t('success.gameCreated'));
          store.commit('toggleSound', true);
          newGameLoading.value = false;
          goToGame();
        } catch (e) {
          errorToast(e);
          newGameLoading.value = false;
        }
      };

      const copy = (): void => {
        navigator.clipboard.writeText(link.value);
        successToast(t('common.copiedToClipboard'));
      };

      const goToGame = (): void => {
        push({
          name: 'funbusinessgame-game',
          params: { companyId: company.value.id, gameId: gameId.value }
        });
      };

      return {
        name,
        cash,
        link,
        avatars,
        user,
        audio,
        gameSounds,
        newGameLoading,
        selectedAvatar,
        gameFinishTime,
        creatorShouldPlayGame,
        buttonDisabled,
        goToGame,
        copy,
        addGame,
        removeOneGameFromPackage,
        userHasPermissionsToCreateGames,
        company
      };
    }
  });
