
  import { ref, watch, defineComponent, Ref } from 'vue';

  import useFirebase from '@/composables/use-firebase';
  import { diceRollSound } from '@/composables/use-audio';
  import { useStore } from 'vuex';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';

  export default defineComponent({
    name: 'BoardInsideDice',
    components: { BaseButtonWithSpinner },
    props: {
      activePlayer: {
        type: Object,
        default: () => ({})
      },
      diceConfig: {
        type: Object,
        default: () => ({})
      },
      actionActive: {
        type: Boolean,
        default: false
      },
      hideDice: {
        type: Boolean,
        default: false
      },
      rollLoading: {
        type: Boolean,
        default: false
      }
    },
    emits: ['roll'],
    setup(props, { emit }) {
      const { getCurrentUser } = useFirebase();
      const user = getCurrentUser();
      const store = useStore();

      const elDiceOne: Ref<HTMLInputElement | null> = ref(null);
      const elDiceTwo: Ref<HTMLInputElement | null> = ref(null);
      const rollClicked: Ref<boolean> = ref(false);

      const makeRollAnimation = (diceOne: number, diceTwo: number) => {
        if (elDiceOne.value && elDiceTwo.value) {
          elDiceOne.value.classList.toggle('animate');
          elDiceTwo.value.classList.toggle('animate');

          for (let i = 1; i <= 6; i++) {
            elDiceOne.value.classList.remove('show-' + i);
            if (diceOne === i) {
              elDiceOne.value.classList.add('show-' + i);
            }
          }

          for (let k = 1; k <= 6; k++) {
            elDiceTwo.value.classList.remove('show-' + k);
            if (diceTwo === k) {
              elDiceTwo.value.classList.add('show-' + k);
            }
          }
        }
      };

      const roll = () => {
        if (rollClicked.value) {
          return;
        }

        let diceOne = Math.floor(Math.random() * 6 + 1);
        let diceTwo = Math.floor(Math.random() * 6 + 1);
        // for easy tests
        // let diceOne = 1;
        // let diceTwo = 3;

        makeRollAnimation(diceOne, diceTwo);

        if (store.state.soundOn) {
          diceRollSound.play();
        }

        rollClicked.value = true;
        setTimeout(() => {
          emit('roll', { diceOne, diceTwo });
        }, 1000);
        setTimeout(() => {
          rollClicked.value = false;
        }, 1000);
      };

      watch(
        () => props.diceConfig,
        (newVal, oldVal) => {
          if (newVal.rolled && !oldVal.rolled) {
            makeRollAnimation(newVal.one, newVal.two);
          }
        }
      );

      return {
        roll,
        elDiceOne,
        elDiceTwo,
        user,
        rollClicked
      };
    }
  });
