import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "player-stats-wrapper" }
const _hoisted_2 = { class: "bold mb2" }
const _hoisted_3 = { class: "player-stats" }
const _hoisted_4 = {
  key: 0,
  class: "flex items-center justify-center player-stats__item-remove"
}
const _hoisted_5 = { class: "player-stats__name-wrapper" }
const _hoisted_6 = { class: "player-stats__heading player-stats__name" }
const _hoisted_7 = { class: "player-stats__owned" }
const _hoisted_8 = { class: "player-stats__money" }
const _hoisted_9 = { class: "player-stats__payroll" }
const _hoisted_10 = { class: "player-stats__payroll" }
const _hoisted_11 = { class: "player-stats__modal" }
const _hoisted_12 = { class: "player-stats__heading--big" }
const _hoisted_13 = { class: "player-stats__cards-wrapper" }
const _hoisted_14 = {
  key: 0,
  class: "player-stats__cards"
}
const _hoisted_15 = { class: "player-stats__heading" }
const _hoisted_16 = { class: "player-stats__heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTooltipWrapper = _resolveComponent("BaseTooltipWrapper")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_BoardCard = _resolveComponent("BoardCard")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('common.players')), 1),
    _createVNode("div", _hoisted_3, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.players, (playerStats, index) => {
        return (_openBlock(), _createBlock("div", {
          class: "player-stats__item",
          key: playerStats.id
        }, [
          (_ctx.checkIfPlayerCanBeDeleted(playerStats.id))
            ? (_openBlock(), _createBlock("div", _hoisted_4, [
                _createVNode(_component_BaseTooltipWrapper, {
                  tooltip: _ctx.$t('common.removePlayer')
                }, {
                  default: _withCtx(() => [
                    _createVNode("i", {
                      class: "pi pi-user-minus",
                      onClick: ($event: any) => (_ctx.handleDeletePlayer(playerStats.id))
                    }, null, 8, ["onClick"])
                  ]),
                  _: 2
                }, 1032, ["tooltip"])
              ]))
            : _createCommentVNode("", true),
          _createVNode("div", {
            class: ["player-stats__item-content", {
            'player-stats__item-content--active': _ctx.activePlayerIndex === index
          }],
            onClick: ($event: any) => (_ctx.activatePlayerStats(index))
          }, [
            _createVNode("div", _hoisted_5, [
              _createVNode("img", {
                class: "player-stats__avatar",
                alt: playerStats.avatar,
                src: 
                require(`@/assets/avatars/${playerStats.avatar.pack}/${playerStats.avatar.name}.svg`)
              
              }, null, 8, ["alt", "src"]),
              _createVNode("div", _hoisted_6, _toDisplayString(playerStats.name), 1),
              (playerStats.admin)
                ? (_openBlock(), _createBlock(_component_Chip, {
                    key: 0,
                    class: "small"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(playerStats.admin ? `${_ctx.$t('players.admin')}` : ''), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (playerStats.id === _ctx.yourPlayer?.id)
                ? (_openBlock(), _createBlock(_component_Chip, {
                    key: 1,
                    class: "small primary ml1"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(playerStats.id === _ctx.yourPlayer?.id ? `${_ctx.$t('players.you')}` : ''), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ]),
            _createVNode("div", _hoisted_7, _toDisplayString(playerStats.owned.length) + " " + _toDisplayString(_ctx.$t('players.cardsOwned')) + "  ", 1),
            _createVNode("div", _hoisted_8, [
              _createTextVNode(_toDisplayString(_ctx.$t('players.cash')) + "  ", 1),
              _createVNode("div", {
                class: ["player-stats__money-value", {
                'player-stats__money-added': _ctx.state.moneyAdded && _ctx.yourPlayer?.id === playerStats.id,
                'player-stats__money-removed':
                  _ctx.state.moneyRemoved && _ctx.yourPlayer?.id === playerStats.id
              }]
              }, _toDisplayString(playerStats.money), 3)
            ]),
            _createVNode("div", _hoisted_9, [
              _createTextVNode(_toDisplayString(_ctx.$t('players.payroll')) + "  ", 1),
              _createVNode("b", null, _toDisplayString(playerStats.payroll), 1)
            ]),
            _createVNode("div", _hoisted_10, [
              _createTextVNode(_toDisplayString(_ctx.$t('common.value')) + "  ", 1),
              _createVNode("b", null, _toDisplayString(_ctx.getPlayerValue(index)), 1)
            ])
          ], 10, ["onClick"]),
          (_openBlock(), _createBlock(_Teleport, { to: "#stats-modal" }, [
            (_ctx.state.statsOpen && _ctx.state.activatedStats)
              ? (_openBlock(), _createBlock("div", {
                  key: 0,
                  class: "modal",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clearStats && _ctx.clearStats(...args)))
                }, [
                  _createVNode("div", _hoisted_11, [
                    _createVNode("div", _hoisted_12, _toDisplayString(_ctx.state.activatedStats.name), 1),
                    _createVNode("div", _hoisted_13, [
                      _createVNode("div", null, _toDisplayString(_ctx.state.activatedStats.owned.length + ' cards owned'), 1),
                      (_ctx.state.activatedStats.owned.length)
                        ? (_openBlock(), _createBlock("div", _hoisted_14, [
                            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.state.activatedStats.owned, (card, i) => {
                              return (_openBlock(), _createBlock(_component_BoardCard, {
                                key: i,
                                card: card,
                                charge: _ctx.getCardCharge(card),
                                "player-name": { name: _ctx.state.activatedStats.name },
                                onTogglePreviewedCard: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('togglePreviewedCard', $event)))
                              }, null, 8, ["card", "charge", "player-name"]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('players.cash')) + "  " + _toDisplayString(_ctx.state.activatedStats.money), 1),
                    _createVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('common.value')) + "  " + _toDisplayString(_ctx.getPlayerValue(_ctx.state.activatedPlayerIndex)), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        ]))
      }), 128))
    ])
  ]))
}