
  import { ref, watch, defineComponent, Ref } from 'vue';

  export default defineComponent({
    name: 'ActiveActionCard',
    props: {
      card: {
        type: Object,
        default: () => null
      }
    },
    setup(props) {
      const showInsideCard: Ref<boolean> = ref(false);

      const handleClose = () => {
        showInsideCard.value = false;
      };

      watch(
        () => props.card,
        (newVal, oldVal) => {
          if ((!oldVal && newVal) || newVal.id !== oldVal.id) {
            showInsideCard.value = true;
          }
        }
      );

      return {
        showInsideCard,
        handleClose
      };
    }
  });
