
  import { watch, computed, shallowRef, toRaw, defineComponent, Ref, ref, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import firebase from 'firebase';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  import BoardInside from '@/components/BoardInside.vue';
  import BoardOutside from '@/components/BoardOutside.vue';
  import BoardPlayers from '@/components/BoardPlayers.vue';
  import PlayerStats from '@/components/PlayerStats.vue';
  import Log from '@/components/Log.vue';
  import AddAdmin from '@/components/AddAdmin.vue';
  import ForceNextPlayer from '@/components/ForceNextPlayer.vue';
  import JoinGame from '@/components/JoinGame.vue';
  import EndResults from '@/views/funbusinessgame/EndResults.vue';
  import ActiveActionCard from '@/components/ActiveActionCard.vue';
  import CardAuction from '@/components/CardAuction.vue';
  import BuyOffer from '@/components/BuyOffer.vue';
  import PreviewedCard from '@/components/PreviewedCard.vue';
  import BaseWaitingForOtherPlayers from '@/components/common/BaseWaitingForOtherPlayers.vue';
  import QuickInstructions from '@/components/common/QuickInstructions.vue';

  import useToast from '@/composables/use-toast';
  import useBoard from '@/composables/use-board';

  import { ACTION_FUNCTIONS } from '@/helpers/automatic-actions';
  import { getPossibleActions, performManualAction } from '@/helpers/manual-actions-config';
  import { handlePayOwner } from '@/helpers';
  import useFirebase from '@/composables/use-firebase';

  import { bonusSound } from '@/composables/use-audio';
  import { calculateUpdatedLog } from '@/helpers/common';
  import { Card, LogItem } from '@/interfaces/businessgame';
  const db = firebase.firestore();

  export default defineComponent({
    name: 'Board',
    components: {
      BoardInside,
      QuickInstructions,
      PlayerStats,
      BoardOutside,
      BoardPlayers,
      EndResults,
      ForceNextPlayer,
      AddAdmin,
      Log,
      JoinGame,
      ActiveActionCard,
      CardAuction,
      BuyOffer,
      PreviewedCard,
      BaseWaitingForOtherPlayers
    },
    setup() {
      const { successToast } = useToast();
      const { t, locale } = useI18n();

      const previewedCard: Ref<Card> = shallowRef({
        id: 0,
        type: ''
      });

      const instructions = computed(() => [
        t('funBusinessGameShortInstructions.0'),
        t('funBusinessGameShortInstructions.1'),
        t('funBusinessGameShortInstructions.2'),
        t('funBusinessGameShortInstructions.3'),
        t('funBusinessGameShortInstructions.4'),
        t('funBusinessGameShortInstructions.5')
      ]);

      const rollLoading: Ref<boolean> = ref(false);
      const cancelGameLoading: Ref<boolean> = ref(false);
      const joinLoading: Ref = ref(false);

      const { getCurrentUser } = useFirebase();

      const user = getCurrentUser();

      const {
        activeGame,
        showJoinGame,
        showPlayerStats,
        showStartGameButton,
        showDebugThings,
        currentPlayer,
        cancelGame,
        joinGame,
        updateGame,
        startGame,
        checkIfGameShouldEnd,
        addAdmin,
        forceNextPlayer
      } = useBoard();

      const store = useStore();
      const { currentRoute } = useRouter();

      onMounted(async () => {
        await getCurrentCompany();
        locale.value = store.state.company.signupLanguage || 'en';
      });

      const copy = (): void => {
        // @ts-ignore
        navigator.clipboard.writeText(
          `https://${window.location.hostname}/funbusinessgame/${currentRoute.value.params.companyId}/${currentRoute.value.params.gameId}`
        );
        successToast(t('common.copiedToClipboard'));
      };

      const getCurrentCompany = async () => {
        const company = await db
          .doc(`companies/${currentRoute.value.params.companyId}`)
          .get()
          .then(doc => {
            return { id: doc.id, ...doc.data() };
          });

        store.commit('setCompany', company);
      };

      const possibleActions = computed(() => {
        return getPossibleActions(activeGame.value, previewedCard.value, user);
      });

      const togglePreviewedCard = (card: Card) => {
        previewedCard.value = card;
      };

      const handleRoll = async ({ diceOne, diceTwo }: { diceOne: number; diceTwo: number }) => {
        rollLoading.value = true;

        let players = activeGame.value.players;
        const activeIndex = activeGame.value.activePlayerIndex;
        const activePlayer = players[activeIndex];

        const result = players[activeIndex].boardField + diceOne + diceTwo;
        const newBoardField = result > 39 ? result - 40 : result;

        let updatedPlayer = { ...activePlayer };
        updatedPlayer.boardField = newBoardField;

        let updatedBoardConfig = activeGame.value.boardConfig;
        let updatedActiveCard = updatedBoardConfig[newBoardField];
        let updatedInsideCard = activeGame.value.activeInsideCard;
        let updatedChanceCards = activeGame.value.chanceCards;
        let updatedBonusMoney = activeGame.value.bonusMoney;

        let nextPlayer = false;

        let newLogItems: LogItem[] = [];
        let dicesTheSame = diceOne === diceTwo;

        newLogItems = calculateUpdatedLog({
          log: newLogItems,
          name: t('log.rolled', { diceOne, diceTwo }),
          player: activePlayer
        });

        if (result > 39) {
          newLogItems = calculateUpdatedLog({
            log: newLogItems,
            name: t('log.payroll', { value: activePlayer.payroll }),
            player: activePlayer
          });

          successToast(t('log.payrollAlert', { value: updatedPlayer.payroll }));

          updatedPlayer.money += updatedPlayer.payroll;
          updatedBonusMoney = updatedBonusMoney + 10;
        }

        if (updatedActiveCard.owner && updatedActiveCard.owner !== updatedPlayer.id) {
          const handlePayOwnerResult = handlePayOwner(
            {
              ...activeGame.value
            },
            updatedActiveCard,
            newLogItems,
            t
          );

          newLogItems = handlePayOwnerResult.log;
          players = handlePayOwnerResult.players;
          updatedPlayer = {
            ...updatedPlayer,
            // there was a bug that payroll wasn't added to the player when they had to pay
            money: result > 39 ? players[activeIndex].money + updatedPlayer.payroll : players[activeIndex].money
          };
          updatedInsideCard = handlePayOwnerResult.activeInsideCard;
          nextPlayer = true;
        }

        if (updatedActiveCard.automaticAction) {
          const result = ACTION_FUNCTIONS[updatedActiveCard.automaticAction]({
            initialChanceCards: activeGame.value.initialChanceCards,
            players,
            player: updatedPlayer,
            log: newLogItems,
            activePlayerIndex: activeIndex,
            chanceCards: activeGame.value.chanceCards,
            bonusMoney: updatedBonusMoney,
            boardConfig: updatedBoardConfig,
            translation: t
          });

          if (result.log) {
            newLogItems.concat(...result.log);
          }

          if (result.updatedPlayer) {
            updatedPlayer = result.updatedPlayer;
          }

          if ('bonusMoney' in result) {
            updatedBonusMoney = result.bonusMoney;
          }

          if (result.updatedInsideCard) {
            updatedInsideCard = result.updatedInsideCard;
          }

          if (result.updatedChanceCards) {
            updatedChanceCards = result.updatedChanceCards;
          }

          if (result.boardConfig) {
            updatedBoardConfig = result.boardConfig;
          }

          nextPlayer = true;
        }

        if (!updatedActiveCard.owner && updatedActiveCard.price > updatedPlayer.money) {
          nextPlayer = true;
        }

        if (
          updatedActiveCard.cannotBeOwned ||
          (updatedActiveCard.owner && updatedActiveCard.owner === updatedPlayer.id)
        ) {
          nextPlayer = true;
        }

        if (dicesTheSame) {
          updatedPlayer.roundsLeft += 1;
        }

        if (updatedPlayer.roundsLeft) {
          updatedPlayer.roundsLeft -= 1;
        }

        await updateGame({
          id: activeGame.value.id,
          log: [...activeGame.value.log, ...newLogItems].slice(0).slice(-10),
          diceConfig: {
            one: diceOne,
            two: diceTwo,
            rolled: true
          },
          players: [
            ...players.slice(0, activeIndex),
            updatedPlayer,
            ...players.slice(activeIndex + 1)
          ],
          actionActive: true,
          activeCard: updatedActiveCard,
          activeInsideCard: updatedInsideCard,
          chanceCards: updatedChanceCards,
          bonusMoney: updatedBonusMoney,
          activePlayerIndex:
            nextPlayer && !updatedPlayer.roundsLeft
              ? activeGame.value.activePlayerIndex === players.length - 1
                ? 0
                : activeGame.value.activePlayerIndex + 1
              : activeGame.value.activePlayerIndex
        });

        if (nextPlayer) {
          const playersWithActions = [
            ...players.slice(0, activeIndex),
            updatedPlayer,
            ...players.slice(activeIndex + 1)
          ].map(p => ({
            ...p,
            actionsAvailable: 1,
            roundsLeft: !updatedPlayer.roundsLeft ? 1 : updatedPlayer.roundsLeft
          }));

          setTimeout(async () => {
            await updateGame({
              boardConfig: updatedBoardConfig,
              id: activeGame.value.id,
              diceConfig: {
                ...activeGame.value.diceConfig,
                rolled: false
              },
              actionActive: false,
              bonusMoney: updatedBonusMoney,
              players: playersWithActions
            });
          }, 500);
        } else {
          setTimeout(() => {}, 1000);
        }

        checkIfGameShouldEnd();
        rollLoading.value = false;
      };

      const handlePerformManualAction = ({
        actionType,
        nextPlayer,
        onOwnedCard
      }: {
        actionType: string;
        nextPlayer: boolean;
        onOwnedCard: boolean;
      }) => {
        // TODO it's a safe measure so that it's not done twice for some reason
        if (onOwnedCard && !previewedCard.value.id) {
          return;
        }

        let shouldNextPlayer = nextPlayer;

        const result = performManualAction(
          actionType,
          activeGame.value,
          toRaw(previewedCard.value),
          t,
          locale.value
        );

        // @ts-ignore
        const players = result.players
          ? // @ts-ignore
            result.players
          : activeGame.value.players;
        const activeIndex = activeGame.value.activePlayerIndex;
        const updatedPlayer = players[activeIndex];

        if (updatedPlayer.roundsLeft || actionType === 'AUCTION') {
          shouldNextPlayer = false;
        }

        const playersWithActions = [
          ...players.slice(0, activeIndex),
          updatedPlayer,
          ...players.slice(activeIndex + 1)
        ].map(p => ({
          ...p,
          actionsAvailable: onOwnedCard ? 0 : 1,
          roundsLeft: !updatedPlayer.roundsLeft ? 1 : updatedPlayer.roundsLeft
        }));

        if (result) {
          updateGame({
            ...result,
            players: playersWithActions,
            id: activeGame.value.id,
            activePlayerIndex: shouldNextPlayer
              ? activeGame.value.activePlayerIndex === activeGame.value.players.length - 1
                ? 0
                : activeGame.value.activePlayerIndex + 1
              : activeGame.value.activePlayerIndex
          });
        } else {
          updateGame({
            id: activeGame.value.id,
            players: playersWithActions,
            activePlayerIndex: shouldNextPlayer
              ? activeGame.value.activePlayerIndex === activeGame.value.players.length - 1
                ? 0
                : activeGame.value.activePlayerIndex + 1
              : activeGame.value.activePlayerIndex
          });
        }

        togglePreviewedCard({
          id: 0,
          type: ''
        });
      };

      const handlePerformManualActionOnOwnedCard = (actionType: string) => {
        handlePerformManualAction({
          actionType,
          nextPlayer: false,
          onOwnedCard: true
        });
      };

      const handleAddAdmin = (id: string) => {
        addAdmin(id);
        successToast(t('common.adminChanged'));
      };

      const handleForceNextPlayer = () => {
        forceNextPlayer();
        successToast(t('common.nextPlayerForced'));
      };

      watch(
        () => activeGame.activeCard,
        newVal => {
          if (newVal.title === 'BONUS' && store.state.soundOn) {
            bonusSound.play();
          }
        }
      );

      const handleCancelGame = async () => {
        cancelGameLoading.value = true;
        await cancelGame();
        successToast(t('common.gameCancelled'));
      };

      const handleJoinGame = async (data: any) => {
        joinLoading.value = true;
        await joinGame(data);
        joinLoading.value = false;
      };

      return {
        possibleActions,
        user,
        showJoinGame,
        showPlayerStats,
        showStartGameButton,
        cancelGameLoading,
        activeGame,
        showDebugThings,
        previewedCard,
        rollLoading,
        currentPlayer,
        joinLoading,
        instructions,
        handleAddAdmin,
        handleCancelGame,
        copy,
        togglePreviewedCard,
        handleJoinGame,
        startGame,
        handleRoll,
        handlePerformManualAction,
        handlePerformManualActionOnOwnedCard,
        handleForceNextPlayer
      };
    }
  });
