import { toRefs, shallowReactive } from 'vue';
import firebase from 'firebase';
import 'firebase/firestore';

import { UseDocumentState } from '@/interfaces/businessgame';
import { useStore } from 'vuex';
import useToast from '@/composables/use-toast';
import { useI18n } from 'vue-i18n';

export default function () {
  const store = useStore();
  const { errorToast } = useToast();
  const { t } = useI18n();

  let state: UseDocumentState = shallowReactive({
    error: '',
    documentData: {},
    loading: false
  });

  const db = firebase.firestore();

  const updateDocument = (documentData: any) => {
    state.loading = true;
    state.error = '';

    let data = { ...documentData };
    delete data[documentData.id];

    let docRef = db.collection(`companies/${store.state.company.id}/games`).doc(documentData.id);

    return docRef
      .update({
        ...data,
        updatedOn: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        state.error = '';
        return docRef.get();
      })
      .then(doc => {
        return { id: doc.id, ...doc.data() };
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error);
        state.error = error;
        state.documentData = null;
        errorToast(t('errors.somethingWentWrong'));
        return { error };
      })
      .finally(() => {
        state.loading = false;
      });
  };

  return {
    ...toRefs(state),
    updateDocument
  };
}
