
  import { computed, defineComponent, PropType } from 'vue';
  import { Player } from '@/interfaces/businessgame';

  export default defineComponent({
    name: 'BoardPlayers',
    props: {
      players: {
        type: Array as PropType<Player[]>,
        default: () => []
      }
    },
    setup(props) {
      const viewportWidth = window.outerWidth;

      const playerStyles = computed(() => {
        return Array.from({ length: props.players && props.players.length }, (_, index) => ({
          styles: calculateAbsolutePosition(
            index,
            // @ts-ignore
            props.players && props.players[index].boardField
          )
        }));
      });

      const calculateAbsolutePosition = (index: number, field: number) => {
        const baseUnit: string = viewportWidth > 1000 ? 'vh' : 'vw';

        const cornerMap = {
          0: `translate(45${baseUnit}, 45${baseUnit})`,
          10: `translate(-45${baseUnit}, 45${baseUnit})`,
          20: `translate(-45${baseUnit}, -45${baseUnit})`,
          30: `translate(45${baseUnit}, -45${baseUnit})`
        };

        const otherFieldsMap = {
          10: `translate(${(5 - field) * 8.9}${baseUnit}, 37${baseUnit})`,
          20: `translate(-37${baseUnit}, ${(15 - field) * 8.9}${baseUnit})`,
          30: `translate(${(25 - field) * -8.9}${baseUnit}, -37${baseUnit})`,
          40: `translate(37${baseUnit}, ${(35 - field) * -8.9}${baseUnit})`
        };

        const transform: string =
          field === 0 || field === 10 || field === 20 || field === 30
            ? cornerMap[field]
            : // @ts-ignore
              otherFieldsMap[Math.ceil((field + 1) / 10) * 10];

        return { transform };
      };

      return { playerStyles };
    }
  });
