
  import { defineComponent } from 'vue';

  import useBoard from '@/composables/use-board';

  export default defineComponent({
    name: 'BuyOffer',
    props: {
      user: {
        type: Object,
        default: () => ({})
      },
      offer: {
        type: Object,
        default: () => ({})
      }
    },
    setup() {
      const { finaliseBuyOffer } = useBoard();

      return {
        finaliseBuyOffer
      };
    }
  });
