
  import { ref, computed, defineComponent } from 'vue';

  import BoardInsideDice from '@/components/BoardInsideDice.vue';
  import PossibleActions from '@/components/PossibleActions.vue';
  import GameFinishTime from '@/components/GameFinishTime.vue';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'BoardInside',
    components: {
      BoardInsideDice,
      GameFinishTime,
      PossibleActions
    },
    props: {
      actions: {
        type: Object,
        default: () => ({})
      },
      game: {
        type: Object,
        default: () => ({})
      },
      user: {
        type: Object,
        default: () => ({})
      },
      rollLoading: {
        type: Boolean,
        default: false
      }
    },
    emits: ['performManualAction', 'roll'],
    setup(props, { emit }) {
      const hideCard = ref(false);
      const store = useStore();

      const handleSelectAction = (action: string) => {
        emit('performManualAction', action);
      };

      const actionsExist = computed(() => {
        return props.actions && Object.values(props.actions).some(v => v);
      });

      const logoPath = computed(() => store.state.company.logo);

      const activePlayer = computed(
        () => props.game && props.game.players && props.game.players[props.game.activePlayerIndex]
      );

      return {
        handleSelectAction,
        hideCard,
        activePlayer,
        actionsExist,
        logoPath
      };
    }
  });
