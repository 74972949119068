
  import { reactive, onMounted, computed, defineComponent, ComputedRef } from 'vue';
  import { formatDistanceToNowStrict, addSeconds, isFuture } from 'date-fns';
  import { useI18n } from 'vue-i18n';

  import BoardCard from '@/components/BoardCard.vue';
  import ErrorMessage from '@/components/ErrorMessage.vue';

  import useBoard from '@/composables/use-board';
  import { buy } from '@/helpers/index';
  import { Player } from '@/interfaces/businessgame';
  import useToast from '@/composables/use-toast';
  import { enGB, pl } from 'date-fns/locale';

  interface State {
    bid: number;
    error: string;
    timeLeft: string;
  }

  export default defineComponent({
    name: 'CardAuction',
    props: {
      user: {
        type: Object,
        default: () => ({})
      }
    },
    components: {
      BoardCard,
      ErrorMessage
    },
    setup(props) {
      let interval: number = 0;
      const { infoToast } = useToast();
      const { t, locale } = useI18n();

      const state: State = reactive({
        bid: 0,
        error: '',
        timeLeft: ''
      });

      const { activeGame, handleBid, endAuction } = useBoard();

      const loggedInPlayer: ComputedRef<Player> = computed(() => {
        return activeGame?.value?.players.find((p: Player) => p.id === props.user.uid);
      });

      const auction = computed(() => {
        return activeGame?.value?.auction;
      });

      const winningPlayer: ComputedRef<Player> = computed(() => {
        return activeGame?.value?.players.find(
          (p: Player) => p.id === auction.value.bidding.player
        );
      });

      const bid = () => {
        if (!state.bid) {
          return;
        }

        if (state.bid > loggedInPlayer.value.money) {
          state.error = t('auction.notEnoughMoney', { bid: state.bid });
          return;
        }

        if (state.bid <= auction.value.bidding.value) {
          state.error = t('auction.bidTooSmall');
          return;
        }

        handleBid(state.bid);
      };

      const updateBid = (value: string) => {
        state.error = '';
        state.bid = Number(value);
      };

      const handleEndOfAuction = () => {
        // TODO handle it more wisely, this is a hack that this request goes from first player in the game XD
        if (props.user.uid === activeGame?.value?.players[0].id && !auction.value.bidding.value) {
          endAuction({
            auction: null
          });
          infoToast(t('auction.nobodyWon'));
        }

        if (auction.value.bidding.value && props.user.id === winningPlayer.value.id) {
          const result = buy(
            activeGame.value,
            activeGame?.value?.activeCard,
            winningPlayer.value,
            auction.value.bidding.value,
            t
          );
          endAuction({
            ...result,
            auction: null
          });
        }

        if (auction.value.bidding.value) {
          setTimeout(() => {
            infoToast(
              t('auction.playerWon', {
                name: winningPlayer.value.name,
                value: auction.value.bidding.value
              })
            );
          }, 500);
        }

        clearInterval(interval);
      };

      const getSecondsLeft = () => {
        if (!auction.value) {
          return;
        }

        const helperDate = addSeconds(new Date(0), auction.value.timeEnds.seconds);

        if (!isFuture(helperDate)) {
          handleEndOfAuction();
        }

        state.timeLeft = formatDistanceToNowStrict(helperDate, {
          locale: locale.value === 'pl' ? pl : enGB
        });
      };

      onMounted(() => {
        interval = setInterval(() => getSecondsLeft(), 1000);
      });

      return {
        state,
        auction,
        winningPlayer,
        handleBid,
        getSecondsLeft,
        updateBid,
        bid
      };
    }
  });
