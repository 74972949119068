<template>
  <div class="force-next-player" @click="$emit('submit')">
    {{ $t('common.forceNextPlayer') }}
  </div>
</template>

<script>
  export default {
    name: 'ForceNextPlayer'
  };
</script>

<style scoped lang="scss">
  .force-next-player {
    height: global-vars(ui-default-measure3x);
    background: white;
    border-radius: global-vars(ui-default-measure);
    box-shadow: global-vars(ui-default-box-shadow--dark);
    cursor: pointer;
    padding: 4px;
    @include font(12px, 600, 15px);
  }
</style>
