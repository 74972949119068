
  import { computed, ref, defineComponent, PropType, ComputedRef, Ref } from 'vue';

  import BoardCardDescription from '@/components/BoardCardDescription.vue';

  import { BUYABLE_CARDS, CARD_TYPES, POSSIBLE_ACTIONS_ON_CARDS } from '@/constants';
  import BaseTeamCard from '@/components/BaseTeamCard.vue';
  import BaseChanceCard from '@/components/BaseChanceCard.vue';
  import BaseOfficeCard from '@/components/BaseOfficeCard.vue';
  import BaseZusCard from '@/components/BaseZusCard.vue';
  import BaseCornerCard from '@/components/BaseCornerCard.vue';
  import BaseIndividualCard from '@/components/BaseIndividualCard.vue';
  import { calculateCardCharge } from '@/helpers/players-helpers';
  import {
    Card,
    Player,
    PossibleActionsOnCards,
    CardUpgradeConfig
  } from '@/interfaces/businessgame';

  export default defineComponent({
    name: 'PreviewedCard',
    components: {
      BoardCardDescription
    },
    props: {
      boardConfig: {
        type: Array as PropType<Card[]>,
        default: () => []
      },
      upgradeConfig: {
        type: Object as PropType<CardUpgradeConfig>,
        default: () => ({})
      },
      cardActions: {
        type: Object,
        default: () => ({})
      },
      actions: {
        type: Object,
        default: () => ({})
      },
      previewedCard: {
        type: Object as PropType<Card>,
        default: () => ({})
      },
      players: {
        type: Array as PropType<Player[]>,
        default: () => []
      },
      activePlayer: {
        type: Object as PropType<Player>,
        default: () => ({})
      },
      bonusMoney: {
        type: Number,
        default: 0
      }
    },
    emits: ['performManualAction', 'performManualActionOnOwnedCard', 'hide'],
    setup(props, { emit }) {
      const BUYABLE: Ref<string[]> = ref(BUYABLE_CARDS);
      const POSSIBLE_ACTIONS: Ref<PossibleActionsOnCards> = ref(POSSIBLE_ACTIONS_ON_CARDS);

      const handleSelectAction = (action: string) => {
        const allow = action === POSSIBLE_ACTIONS.value.SELL ? confirm('Are you sure?') : true;

        if (allow) {
          emit('performManualAction', {
            actionType: action,
            nextPlayer: false
          });
        }
      };

      const actionAvailable: ComputedRef<boolean> = computed(
        () => props.activePlayer.actionsAvailable > 0
      );

      const previewedCardSaleValue: ComputedRef<number> = computed(() => {
        if (
          props.previewedCard &&
          props.previewedCard.team &&
          props.previewedCard.prices &&
          props.previewedCard.price
        ) {
          return (
            props.previewedCard.price * 0.5 +
            props.previewedCard.team.categoryOne * props.previewedCard.prices.categoryOne.sell +
            props.previewedCard.team.categoryTwo * props.previewedCard.prices.categoryTwo.sell +
            props.previewedCard.team.categoryThree * props.previewedCard.prices.categoryThree.sell +
            props.previewedCard.team.categoryFour * props.previewedCard.prices.categoryFour.sell
          );
        } else {
          return 0;
        }
      });

      const boardCardComponent = computed(() => {
        switch (props.previewedCard.type) {
          case CARD_TYPES.TEAM:
            return BaseTeamCard;
          case CARD_TYPES.CHANCE:
            return BaseChanceCard;
          case CARD_TYPES.OFFICE:
            return BaseOfficeCard;
          case CARD_TYPES.ZUS:
            return BaseZusCard;
          case CARD_TYPES.CORNER:
            return BaseCornerCard;
          case CARD_TYPES.INDIVIDUAL:
            return BaseIndividualCard;
          default:
            return BaseTeamCard;
        }
      });

      const owner: ComputedRef<Player | undefined> = computed(() =>
        props.players.find(
          // @ts-ignore
          (player: Player) => player.id === props.previewedCard.owner
        )
      );

      const cardCharge: ComputedRef<number> = computed(() =>
        calculateCardCharge(props.boardConfig, props.previewedCard)
      );

      return {
        BUYABLE,
        POSSIBLE_ACTIONS,
        actionAvailable,
        previewedCardSaleValue,
        boardCardComponent,
        handleSelectAction,
        owner,
        cardCharge
      };
    }
  });
